.items-wrapper {
  flex: 1 1;
  display: flex;
  overflow: auto;

  .item-card {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    max-width: 150px;
    min-width: 150px;
    min-height: 150px;
    max-height: 150px;
    margin-bottom: 0;
  }
}

.item-card {
  display: block;
  min-height: 100px;
  background-color: $color-white;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
    background-color: $color-primary;
    color: $color-white;
  }

  .title,
  .price,
  .description,
  .out-of-stock {
    display: block;
  }

  .title,
  .description {
    margin-bottom: 1rem;
  }

  .out-of-stock {
    margin-top: 1rem;
  }

  .title {
    font-weight: bold;
  }

  &--disabled {
    background-color: $color-grey-light;
    color: #808080;

    &:hover {
      cursor: not-allowed;
      background-color: $color-grey-light;
      color: #808080;
    }
  }
}