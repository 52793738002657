// Variables
$color-primary: #2f2e41;
$color-primary-light: #f6f7ff;
$color-border: #ddd;
$color-white: #fff;
$color-success: #3F6634;
$color-grey-light: rgb(238, 238, 238);
$color-orange: #febd2e;
$color-red: #ee757a;

$breakpoint-mobile: 576px;
$breakpoint-mobile-portrait: 768px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 992px;
$breakpoint-desktop: 1200px;

// Components
@import './components/badge';
@import './components/basket';
@import './components/button';
@import './components/container';
@import './components/input';
@import './components/item';
@import './components/loading';
@import './components/local-nav';
@import './components/main-nav';
@import './components/modal';
@import './components/notice';
@import './components/order';
@import './components/skeleton';

// Util
@import './util/display';
@import './util/margin';
@import './util/typography';
