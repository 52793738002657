.notice {
  padding: 1rem;
  color: $color-white;
  background-color: $color-primary;
  border-radius: 1rem;
  margin-top: 2rem;

  svg {
    margin-right: 10px;
  }
}