.skeleton {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #d8d8d8;
  background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;

  &__input-text {
    width: 100%;
    height: 55px;
    margin: 0 0 1.25rem;
    border-radius: 5px;
  }

  &__input-label {
    width: 150px;
    height: 20px;
    margin: 4px 0;
  }
  
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}