.local-nav {
  overflow-x: auto;
  white-space: nowrap;

  ul {
    list-style: none;
    margin: 0;
    margin-top: 2rem;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 1rem;
      padding: 0.75rem 1rem;
      border-radius: 1rem;

      a {
        text-decoration: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .is-active {
      background-color: $color-primary;

      a {
        color: $color-white;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .local-nav {
    min-width: 150px;
    max-width: 150px;

    ul {
      margin-top: 0;

      li {
        display: block;
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}
