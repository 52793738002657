.logo {
  font-family: "Baloo Bhaina 2", cursive;
  color: #333 !important;
  text-decoration: none !important;
  font-size: 2rem !important;
  line-height: 2rem;

  .primary {
    color: #7ca870 !important;
  }
}
