.basket {
  border: 1px solid $color-grey-light;
  border-radius: 1rem;

  &-section {
    &-header {
      display: block;
      background-color: $color-primary;
      color: $color-white;
      font-weight: 600;
      padding: 0.75rem 1rem;
      text-transform: capitalize;
    }

    &:first-child {
      .basket-section-header {
        border-radius: 1rem 1rem 0 0;
      }
    }

    .basket-item {
      padding: 1rem;
      background-color: $color-white;

      &-details {
        display: inline-block;
        width: 80%;
      }

      &-price {
        display: inline-block;
        width: 20%;
        text-align: right;
      }

      &-quantity {
        background-color: $color-primary;
        color: $color-white;
        padding: 0 5px;
        margin-right: 10px;
        border-radius: 5px;
      }

      &:hover {
        cursor: pointer;
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

  &-footer {
    margin-top: 2rem;
  }

  &-actions {
    margin: 2rem 0;
  }

  &-success {
    text-align: center;

    .basket-success-icon {
      font-size: 4rem;
      color: $color-primary;
    }

    .basket-success-text {
      font-size: 1.25rem;
    }
  }
}

.secure-payment {
  background-color: rgba($color: #7ca870, $alpha: 0.25);
  border: 1px solid #7ca870;
  border-radius: 0.5rem;
  padding: 0.75rem;

  svg {
    margin-right: 0.5rem;
  }
}

.processing-payment-wrapper {
  text-align: center;
  margin-top: 3rem;

  span {
    display: block;
    font-weight: 700;
  }
}

.success-payment-wrapper {
  text-align: center;
  margin-top: 3rem;

  svg {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  span {
    display: block;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.payment-choice {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 1rem;

  li {
    border: 1px solid $color-border;
    border-radius: 0.5rem;
    display: inline-block;
    margin-right: 1rem;
    padding: 1rem;

    &:hover {
      background-color: $color-grey-light;
      cursor: pointer;
    }
  }

  .selected,
  .selected:hover {
    cursor: initial;
    border-color: #4a8be3;
    background-color: rgb(225, 237, 255);

    &::after {
      content: '\2713';
      color: #fff;
      position: relative;
      top: -25px;
      left: 25px;
      width: 15px;
      height: 15px;
      padding: 0 5px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background-color: #4a8be3;
    }
  }
}
