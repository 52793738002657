.order-page-title {
  display: none;
}

.order-page-all-wrapper {
  margin-bottom: 2rem;
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .order-page-title {
    display: block;
    margin: 2rem 0 1rem 0;
    font-size: 3rem;
    font-weight: 600;
  }

  .order-page-wrapper {
    display: grid;
    grid-template-columns: 150px auto;
    grid-column-gap: 2rem;
    width: 100%;
  }

  .order-page-all-wrapper {
    margin-top: -1rem;
  }
}
