.top-nav {
  .heading-wrapper {
    display: inline-block;

    .title {
      font-size: 2.75rem;
      font-weight: 700;
      display: block;
      font-family: "Montserrat Alternates", sans-serif;
    }

    .subtitle {
      font-size: 1.4rem;
      font-weight: 700;
      display: block;
      color: $color-white;
    }

    .reset-location {
      font-size: 1rem;
      font-weight: 500;
      display: block;
      color: $color-white;
      cursor: pointer;
      width: fit-content;
    }
  }

  .icons {
    width: 3rem;
    height: 3rem;
    background: $color-white;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    position: relative;
    top: 1.15rem;
    float: right;

    &:hover {
      background-color: $color-primary;

      svg {
        color: $color-white;
      }
    }

    svg {
      font-size: 2rem;
      float: left;
      position: relative;
      top: 7px;
      left: 6px;
      color: #06122d;
    }

    .badge {
      position: relative;
      left: 25px;
      top: -40px;
      line-height: 26px;
      background-color: $color-primary;
    }
  }
}

.hero-wrapper {
  border-radius: 1rem;
  background-color: $color-orange;
  background-image: url(../../images/background.svg);
  padding: 1rem 0 0 0;
  min-height: 250px;
  max-height: 250px;
  margin: 4px;

  .hero-content {
    .hero-left {
      display: none;
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .hero-wrapper {
    .hero-content {
      display: flex;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      box-sizing: border-box;

      .hero-left {
        display: unset;

        .hero-title {
          display: block;
          font-size: 3rem;
          font-weight: 600;
          margin-top: 1rem;
        }

        .hero-subtitle {
          display: inline-block;
          font-size: 2.5rem;
          font-weight: 600;
          color: $color-white;
          position: relative;
          top: -1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .hero-wrapper {
    min-height: 40vh;
    max-height: 40vh;

    .hero-content {
      .hero-left {
        .hero-title {
          font-size: 5rem;
          font-weight: 600;
        }

        .hero-subtitle {
          font-size: 3rem;
        }
      }
    }
  }
}
