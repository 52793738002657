.badge {
  display: inline-block;
  min-width: 26px;
  min-width: 1.625rem;
  padding: .1em;
  border-radius: 50%;
  font-size: 14px;
  font-size: .875rem;
  text-align: center;
  background: $color-primary;
  color: $color-white;
}