.ReactModal__Content {
  inset: initial !important;
  top: initial !important;
  left: initial !important;
  right: initial !important;
  padding: 0 !important;
  position: fixed !important;
  height: 100%;
  border: none !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

@media only screen and (min-width: $breakpoint-tablet) {
  .ReactModal__Content {
    inset: unset !important;
    top: 40px !important;
    left: 40px !important;
    right: 40px !important;
    margin: auto;
    max-width: 400px;
    // TODO - max height needs to be improved
    height: unset;
  }

  .modal {
    &-item-size {
      &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;
        width: 100%;

        span {
          display: block;
          text-align: center;

          &:last-child {
            float: unset !important;
          }
        }
      }
    }
  }
}

.modal {
  box-sizing: border-box;
  overflow: hidden;
  display: inline-flex;
  flex-flow: wrap;
  text-decoration: none;
  text-align: center;
  height: 100%;
  background-color: #fcfaf9;

  .modal-heading {
    background-color: $color-orange;
    background-image: url(../../images/background.svg);
    max-height: 250px;
    padding: 1.5rem;
    text-align: left;
    width: 100%;
    margin: 4px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: $color-white;
      -moz-border-radius: 70px;
      -webkit-border-radius: 70px;
      border-radius: 70px;
      text-align: center;

      svg {
        font-size: 1rem;
        position: relative;
        top: 6px;
      }
    }

    .details {
      .title {
        display: block;
        font-size: 2.2rem;
        font-weight: 600;
        color: #fff;
      }

      .description {
        display: block;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
  }

  .modal-body {
    padding: 1rem;
    height: 100%;
    width: 100%;
    font-weight: 600;

    &-inside {
      .size-title {
        text-align: left;
        font-size: 1.25rem;
      }
    }
  }

  &-title {
    display: block;
    font-size: 1.5rem;
    padding: 0 3.5rem;

    span {
      display: inline-block;
    }
  }

  &-subtitle {
    display: block;
    margin: 0.5rem 0;
    font-weight: 400;
    padding: 0 3.5rem;
  }

  &-item-quantity {
    text-align: center;
    margin: 2rem 0 0 0;
    font-size: 20px;

    &-wrapper {
      border-radius: 15px;
      background-color: #f4f4f5;
      display: inline-block;
      padding: 3px 3px 3px 10px;
    }

    &-button {
      color: $color-primary;
      padding: 0 10px;

      &:hover {
        cursor: pointer;
      }
    }

    &-number {
      display: inline-block;
      height: 26px;
      width: 26px;
      background-color: $color-white;
      border-radius: 50%;
    }

    &-price {
      margin-right: 10px;
    }

    &-adjust {
      display: inline-block;
      background-color: $color-orange;
      border-radius: 15px;
      padding: 4px 0;
    }
  }

  &-item-size {
    &-wrapper {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.5rem;

      div {
        cursor: pointer;
        display: inline-block;
        background-color: $color-white;
        padding: 2.5rem 1rem;
        border-radius: 2rem;
        border: 1px solid #f4f4f5;

        span {
          display: block;
        }
      }

      .active {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .ReactModal__Content {
    border-radius: 1rem !important;
    background-color: #fcfaf9 !important;
  }

  .modal {
    background-color: #fcfaf9;
    border: 1px solid #fcfaf9;
    border-radius: 1rem;
    width: 100%;

    &-heading {
      display: none;
    }
  }
}
