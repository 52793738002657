.check-in-page__root {
  height: 100vh;
}

.check-in-page__layout {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

.check-in-page__container {
  justify-content: center;
  margin: 0 16px;
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.check-in-page__form {
  width: 382px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.check-in-page__header {
  padding: 1rem 0;
}

.check-in-card__wrapper {
  border-radius: 6px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.09);
  overflow: hidden;
  background-color: #fff;
  box-sizing: border-box;
  flex-shrink: 0;
}

.check-in-card__container {
  padding: 32px 20px;

  .check-in-card__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.2px;
  }

  .check-in-card__qr-wrapper {
    margin-top: 32px;
    text-align: center;
  }
}

.check-in-card__code-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.check-in-code__container {
  .check-in-code__character {
    font-size: 24px;
    padding: 10px 0;
    min-width: 45px;
    max-width: 45px;
    border: 1px solid rgb(216, 216, 216);
    border-right: none;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
      border-right: 1px solid rgb(216, 216, 216);
    }
  }

  .check-in-code__character--selected {
    outline: solid 3px rgb(190, 217, 235);
  }
}

.check-in-footer__layout {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 12px;
}

.check-in-footer__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.check-in-footer__separator {
  font-weight: 600;
  position: relative;
  bottom: 4px;
  margin: 0 8px;
}

.check-in-page__spacer {
  flex-grow: 1;
  flex-shrink: 1;
}

@media only screen and (min-width: 992px) {
  .check-in-page__form {
    width: 540px;
    min-width: 540px;
  }

  .check-in-page__header {
    padding: 2.75rem 0;
  }

  .check-in-card__container {
    padding: 56px 48px;
  }

  .check-in-card__code-wrapper {
    margin: 2rem 0;
  }

  .check-in-code__container {
    .check-in-code__character {
      min-width: 54px;
      max-width: 54px;
    }
  }
}
