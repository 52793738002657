label {
  display: block;
  margin-top: 1rem;
}

input, #cardNumber, #expiryDate, #cvc {
  border: none;
  width: 100%;
  background-color: #fcfaf9;
  height: 2rem;
  border-bottom: 2px solid #ccc;

  &:focus {
    outline: 0;
    border-bottom: 2px solid $color-primary;
  }
}

#cardNumber, #expiryDate, #cvc {
  padding: 10px 0 0 0;
}
