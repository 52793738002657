.container {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;

  max-width: 75rem;
}
