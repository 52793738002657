.hero {
  &__layout {
    display: flex;
    flex-direction: column;
  }

  &__nav-wrapper {
    height: 30vh;
    object-fit: cover;
    position: relative;
    background-color: #7ca870;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &::after {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      background-image: linear-gradient(270deg,transparent 0,rgba(0,0,0,.002) .8%,rgba(0,0,0,.008) 4.8%,rgba(0,0,0,.021) 9%,rgba(0,0,0,.042) 13.9%,rgba(0,0,0,.075) 19.8%,rgba(0,0,0,.126) 27%,rgba(0,0,0,.194) 35%,rgba(0,0,0,.278) 43.5%,rgba(0,0,0,.382) 53%,rgba(0,0,0,.541) 66%,rgba(0,0,0,.738) 81%,rgba(0,0,0,.9));
    }
  }
}

@media only screen and (min-width: 992px) {
  .hero {
    &__nav-wrapper {
      height: 60vh;
    }
  }
}