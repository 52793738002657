$color-button-flat: rgba(248, 174, 112, 1);
$color-button-gradient: linear-gradient(90deg, rgba(245, 119, 118, 1) 0%, rgba(248, 174, 112, 1) 100%);

.event-page__container-override {
  position: relative;
  top: -5rem;
  overflow: hidden;
}

.event-page {
  &__layout {
    display: flex;
    flex-direction: column;
  }
}

.event-details {
  &__description {
    white-space: break-spaces;
  }

  &__layout {
    display: flex;
    flex-direction: column;
  }

  &__line-mobile {
    margin-top: 1rem;
    font-size: 0.9rem;

    svg {
      color: #f57776;
      margin-right: 5px;
    }
  }

  &__line-desktop {
    display: none;
  }

  &__card-wrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 18px #d8d8d8;
  }

  &__card-top,
  &__card-bottom {
    padding: 1.5rem;
  }

  &__card-mid {
    display: flex;
    flex-direction: row;

    &-dot-first,
    &-dot-last {
      height: 26px;
      width: calc(26px * 0.75);
      position: relative;
      top: 2px;
    }

    &-dot-first {
      background: radial-gradient(circle at left, transparent calc(26px / 2 - 1px), #fff calc(26px / 2));
      background-color: #eeeeee;
    }

    &-dot-last {
      background: radial-gradient(circle at right, transparent calc(26px / 2 - 1px), #fff calc(26px / 2));
      background-color: #eeeeee;
    }

    &-separator {
      display: flex;
      position: relative;
      width: 100%;
      height: calc(calc(26px / 2) + 3px / 2);
      border-top: none;
      border-right: none;
      border-left: none;
      border-image: initial;
      border-bottom: 3px dashed;
      margin: 0px;
      color: #eeeeee;

      &::before {
        content: "";
        height: 100%;
        background: none;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        left: 0px;
      }
    }

    &::after {
      content: "";
      background: none;
      position: absolute;
      width: 100%;
      left: 0px;
      height: none;
      top: 100%;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-top: none;
    }
  }

  &__card-bottom {
    h2 {
      margin: 0;
      font-size: 1.25rem;
    }

    button {
      width: 100%;
      margin-top: 1rem;
      background: rgb(245, 119, 118);
      background: $color-button-gradient;
      border: none;

      &:hover,
      &:focus {
        border: none;
      }
    }
  }
}

.event-tickets {
  &__layout {
    display: flex;
    flex-direction: column;
  }
}

.event-ticket {
  &__layout {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  &__wrapper {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid #d1d1d1;
  }

  &__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__icon {
      min-height: 42px;
      max-height: 42px;
      min-width: 42px;
      max-width: 42px;
      background: $color-button-flat;
      border-radius: 8px;
      text-align: center;
      margin-right: 1rem;

      svg {
        color: #fff;
        position: relative;
        font-size: 20px;
        top: 10px;
      }
    }

    &__name-wrapper {
      display: flex;
      flex-direction: row;
    }

    &__name {
      margin-right: 1rem;
      &-label {
        display: block;
        font-weight: 600;
      }
    }

    &__available {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
      padding: 0 0.75rem;
      border-radius: 22px;
      background: #eef3e8;
      color: #344d0f;
      font-weight: 600;
      text-transform: uppercase;

      &--limited {
        background: #ffe600;
        color: #333;
      }

      &--gone {
        background: #f4d9d7;
        color: #a6352c;
      }
    }

    &__size-name {
      font-weight: 600;
    }

    &__price {
      text-align: end;
      margin-right: 5px;
    }

    &__price-vat {
      font-size: 0.75rem;
      color: #666;
    }
  }

  &__hr {
    border-top: 1px dashed rgb(206, 206, 206);
    margin: 1rem 0 0;
  }

  &__purchase {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    &__button-wrapper {
      min-width: 150px;
      text-align: right;
    }

    button {
      background: $color-button-flat;
      border-color: $color-button-flat;
      padding: 10px 15px;

      &:hover {
        cursor: pointer;
        background: #fff;
        color: $color-button-flat;
        border-color: $color-button-flat;
      }
    }

    &-qty {
      margin: 0 10px;
    }
  }

  &__allergens {
    margin-top: 1rem;
    border-radius: 4px;
    background-color: #f7fafc;
    padding: 12px 20px;

    &-label {
      display: block;
      font-weight: 600;
    }
  }
}

#event-checkout-btn,
#submit-payment-btn {
  background: rgb(245, 119, 118);
  background: $color-button-gradient;
  border: none;

  &:hover {
    cursor: pointer;
    background: #fff;
    background: rgb(245, 119, 118);
    background: $color-button-gradient;
    border: none;
  }
}

.event-payment {
  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  &__back-btn {
    margin-bottom: 1rem;

    svg {
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__form {
    input,
    textarea {
      display: block;
      padding: 7px 15px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 1rem;
      width: calc(100% - 30px);
      background-color: #fff;
    }

    textarea {
      height: 50px;
      font-family: "Nunito", sans-serif;
    }

    #submit-payment-btn {
      width: 100%;
      padding: 15px;
      height: unset;
    }
  }

  &__total {
    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-label {
      font-weight: 600;
    }

    &-vat {
      font-size: 0.75rem;
      color: #666;
    }
  }
}

@media only screen and (min-width: 992px) {
  .event-page__container-override {
    top: -10rem;
  }

  .event-details {
    &__line-mobile {
      display: none;
    }

    &__line-desktop {
      display: block;
      margin-top: 1rem;
      font-size: 1rem;

      svg {
        color: #f57776;
        margin-right: 5px;
      }

      &-label {
        color: #666;
        font-size: 0.9rem;
        font-weight: 600;
      }

      &-value {
        display: block;
        margin-top: 3px;
      }
    }

    &__card-wrapper {
      flex-direction: row;
      border-radius: 1rem;
      min-height: 17.5rem;
    }

    &__card-top {
      flex-basis: 60%;
    }

    &__card-bottom {
      flex-basis: 24%;
    }

    &__card-top,
    &__card-bottom {
      padding: 2.25rem;
    }

    &__card-mid {
      flex-direction: column;

      &-dot-first,
      &-dot-last {
        width: 26px;
        top: 0;
      }

      &-dot-first {
        background: radial-gradient(circle at top, transparent calc(26px / 2 - 1px), #fff calc(26px / 2));
        background-color: #eeeeee;
      }

      &-dot-last {
        background: radial-gradient(circle at bottom, transparent calc(26px / 2 - 1px), #fff calc(26px / 2));
        background-color: #eeeeee;
      }

      &-separator {
        border-bottom: none;
        border-right: 3px dashed;
        width: calc(calc(32px / 2) + 3px / 2);
        height: 100%;
        background: transparent;
        right: 5px;

        &::before {
          content: "";
          height: 100%;
          background: none;
          position: absolute;
          top: 0px;
          right: 0px;
          width: 100%;
          left: unset;
        }
      }

      &::after {
        content: "";
        height: 100%;
        background: none;
        position: absolute;
        top: 100%;
        width: none;
        left: 100%;
        margin-left: none;
      }
    }
  }

  .event-payment {
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
    }

    &__form-wrapper,
    &__basket-wrapper {
      flex-basis: 50%;

      .event-ticket__details__price {
        display: block;
      }
    }
  }
}

#cardNumber,
#expiryDate,
#cvc {
  display: block !important;
  padding: 15px !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  padding-bottom: 0rem !important;
  width: calc(100% - 30px) !important;
  background-color: #fff !important;

  &:focus {
    outline: 0 !important;
    border-bottom: 2px solid #2f2e41 !important;
  }
}

.input--2 {
  display: flex;
  flex-direction: row;
}

.input--2 .input--2-wrapper {
  flex-basis: 50%;
}

.margin--right {
  margin-right: 1rem;
}

.terms-wrapper {
  display: flex;
  margin-bottom: 1rem;
}

.terms-wrapper input {
  display: inline-block;
  padding: 0;
  width: 24px;
  margin-top: 12px;
  margin-right: 12px;
}

.__react_component_tooltip {
  max-width: 300px;
}

.error {
  font-weight: 600;
  color: #bb2528;
}

.error-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.error-animation {
  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
  animation: kf_shake 0.4s 1 linear;
}

.payment-info {
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
}

.event-payment-amount {
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;

  &--option {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    padding: 1rem;
    padding-top: 0.2rem;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }

    label {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &--value {
      font-weight: 400;
    }

    input {
      width: 2.1rem;
      position: relative;
      top: 12px;
    }
  }
}

.event-payment-date {
  border-left: 3px solid #f8ae70;
  padding-left: 0.5rem;

  .event-payment-date__final-payment {
    display: block;
    font-weight: 600;
  }
}

.event-ticket__details__description {
  margin-bottom: 0;
}

.deposit-banner {
  background-color: #c2d8bb;
  border-radius: 5px;
  padding: 0.25rem 2rem;
  margin-top: 1rem;

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: 5px;
  }
}

@-webkit-keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
@-moz-keyframes kf_shake {
  0% {
    -moz-transform: translate(30px);
  }
  20% {
    -moz-transform: translate(-30px);
  }
  40% {
    -moz-transform: translate(15px);
  }
  60% {
    -moz-transform: translate(-15px);
  }
  80% {
    -moz-transform: translate(8px);
  }
  100% {
    -moz-transform: translate(0px);
  }
}
@-o-keyframes kf_shake {
  0% {
    -o-transform: translate(30px);
  }
  20% {
    -o-transform: translate(-30px);
  }
  40% {
    -o-transform: translate(15px);
  }
  60% {
    -o-transform: translate(-15px);
  }
  80% {
    -o-transform: translate(8px);
  }
  100% {
    -o-origin-transform: translate(0px);
  }
}
@keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
