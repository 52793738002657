h1 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

a, .primary {
  color: $color-primary;
}

.p--error {
  padding: 10px 5px 10px 15px;
  background-color: #fae0e0;
  border-left: 5px solid $color-red;
}

.capitalise {
  text-transform: capitalize;
}

.error-animated {
  margin-bottom: 1rem;
  font-weight: 600;
  color: $color-red;

  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
  animation: kf_shake 0.4s 1 linear;
}

@-webkit-keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
@-moz-keyframes kf_shake {
  0% {
    -moz-transform: translate(30px);
  }
  20% {
    -moz-transform: translate(-30px);
  }
  40% {
    -moz-transform: translate(15px);
  }
  60% {
    -moz-transform: translate(-15px);
  }
  80% {
    -moz-transform: translate(8px);
  }
  100% {
    -moz-transform: translate(0px);
  }
}
@-o-keyframes kf_shake {
  0% {
    -o-transform: translate(30px);
  }
  20% {
    -o-transform: translate(-30px);
  }
  40% {
    -o-transform: translate(15px);
  }
  60% {
    -o-transform: translate(-15px);
  }
  80% {
    -o-transform: translate(8px);
  }
  100% {
    -o-origin-transform: translate(0px);
  }
}
@keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
