button {
  color: $color-white;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: $color-white;
    border: 1px solid $color-primary;
    color: $color-primary;
  }
}

.button--secondary {
  color: $color-primary;
  background-color: $color-white;
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white;
  }
}

.button--full-width {
  display: block;
  width: 100%;
}

.button--text {
  color: $color-primary;
  background-color: $color-white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  font-weight: 600;

  &:hover {
    background-color: none;
    border: none;
    color: $color-primary;
    text-decoration: underline;
  }
}

.button--loading {
  height: 47px;
  max-height: 47px;
  padding: 3px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .lds-ring,
  .lds-ring div {
    height: 28px;
    width: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lds-ring div {
    border: 4px solid #fff;
    border-color: #fff transparent transparent transparent;
  }

  &:hover {
    cursor: default;
    color: $color-white;
    background-color: $color-primary;
    border: 1px solid $color-primary;
  }
}
